/* BlogPage.css */

/* General container for the grid */
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 16px;
    padding: 16px;
}

/* Style for each product card */
.product-card {
    background-color: #00000;
    border-radius: 0px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.product-card:hover {
    transform: scale(1.05);
}

/* Images within each product card */
.product-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-bottom: 0px solid #ddd;
}

/* Text styling for product names */
.product-card h3 {
    font-size: 16px;
    padding: 8px;
    text-align: center;
}

/* Responsive media query for smaller screens */
@media (max-width: 768px) {
    .product-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 480px) {
    .product-grid {
        grid-template-columns: 1fr;
    }
}
