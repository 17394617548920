.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid #ddd;
    z-index: 1000;
}

.nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
    font-size: 14px;
    width: 25%;
}

.nav-item span {
    margin-top: 5px;
    font-size: 12px;
}

.nav-item svg {
    font-size: 24px;
}

.nav-item:active,
.nav-item:hover {
    color: #007BFF;
}
