/* Base styles for ShopPage */
.shop-page-container {
    padding: 0px;
    max-width: 100%; /* Use full width for mobile screens */
    margin: 0 auto; /* Center the container */
    box-sizing: border-box;
}

.shop-title {
    text-align: center;
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #333;
}

/* Product grid styling */
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adapt grid based on screen size */
    gap: 20px;
    margin-bottom: 30px;
}

.product-grid-item {
    border: 0px solid #eaeaea;
    padding: 10px;
    background-color: #fff;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-grid-item img {
    width: 100%;
    height: auto;
    max-width: 300px; /* Ensure images don't exceed a certain width */
}

.product-name {
    font-size: 1.2em;
    margin-top: 10px;
    text-align: center;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .shop-title {
        font-size: 1.5em; /* Smaller text size for mobile */
    }

    .product-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust grid for tablet-sized screens */
        gap: 15px;
    }

    .product-grid-item {
        padding: 0px;
    }

    .product-grid-item img {
        max-width: 250px;
    }
}

@media (max-width: 480px) {
    .shop-title {
        font-size: 1.2em; /* Further reduction for small smartphones */
    }

    .product-grid {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); /* Make sure grid items don't get too small */
        gap: 10px;
    }

    .product-grid-item {
        padding: 0px;
    }

    .product-grid-item img {
        max-width: 180px;
    }

    .product-name {
        font-size: 1em; /* Make text smaller but still readable */
    }
}
