/* PDF container - makes sure it fits well on different screen sizes */
.pdf-container {
    width: 100%; /* Full width of the screen */
    padding: 0;
    margin: 20px 0;
}

.responsive-pdf {
    width: 100%;
    height: 100vh; /* Full viewport height for better viewing on mobile */
}

/* Mobile specific adjustments */
@media screen and (max-width: 768px) {
    .responsive-pdf {
        height: 80vh; /* Adjust height for mobile devices */
    }
}

@media screen and (max-width: 480px) {
    .responsive-pdf {
        height: 75vh; /* Further adjustments for smaller screens */
    }
}
