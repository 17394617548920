/* styles.css */

.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 20px;
}

.product-card {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.product-detail-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-detail {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}
