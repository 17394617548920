.drawing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.drawing-canvas {
    border: 2px solid black;
    cursor: crosshair;
    margin-bottom: 10px;
}

button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.posted-drawings {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.drawing-item {
    margin: 10px;
}

.drawing-item img {
    border: 2px solid black;
    width: 150px;
    height: 150px;
}

/* Custom styles for the zoom slider */
.zoom-slider {
    width: 200px; /* Adjust the width to your preference */
    height: 50px; /* Adjust the height for a thicker slider */
}

.zoom-slider::-webkit-slider-thumb {
    width: 25px; /* Adjust for a larger thumb */
    height: 25px; /* Adjust for a larger thumb */
    background-color: #007bff; /* Customize thumb color */
    border-radius: 50%; /* Make it circular */
    cursor: pointer;
}

.zoom-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;
}

